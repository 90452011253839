.Inner {
    @media (--tablet) {
        @mixin wrapper;
    }
}

.Title {
    @mixin h30;
    font-weight: 600;
    margin-bottom: 32px;

    @media (--mobileOnly) {
        @mixin wrapper;
    }

    @media (--tablet) {
        @mixin h20;
        text-align: center;
        margin-bottom: 64px;
    }
}

.List {
    @mixin clearList;

    & > li {
        margin-bottom: 8px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.AccordionTitle {
    @mixin p20;
    margin: 0;

    @media (--tablet) {
        @mixin h40;
    }
}

.AccordionBody {
    padding: 32px 32px 64px;

    @media (--tablet) {
        lost-offset: 1/10;
        lost-column: 8/10 0;
        padding: 80px 0 96px;
    }
}

.Variant--default {
    & .Content {
        @media (--tablet) {
            lost-offset: 1/12;
            lost-column: 10/12 flex;
        }
    }
}

.Variant--grid {
    & .Inner {
        @media (--desktop) {
            @mixin grid;
            grid-template-columns: repeat(12, 1fr);
        }
    }

    & .Content {
        @media (--tablet) {
            grid-column: 1 / span 12;
        }

        @media (--desktopHD) {
            grid-column: 3 / span 8;
        }
    }
}

.Variant--light {
    background-color: var(--whiteBaseColor);
}

.Variant--gray {
    background-color: var(--blackLighten60Color);
}

.Variant--dark {
    background-color: var(--blackLighten10Color);

    & .Title {
        color: var(--whiteBaseColor);
    }
}

.Variant--defaultSpacing {
    padding: 64px 0;

    @media (--tablet) {
        padding: 80px 0;
    }
}
